// 邮箱正则
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// 表情正则
const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;

// 判断是否为邮箱
const isEmail = email => {
  return emailRegExp.test(email);
};
// 判断是否存在表情
const isEmoji = val => {
  return emojiRegex.test(val);
};
export { emailRegExp, isEmail, isEmoji };
