import { webLink } from '@/utils/constant/link.js';
import { cookie, windowOpen } from '@/utils/common/CommonUtils.js';
import env from '@/config/env';
let appQrcode = require('@/assets/images/portal/appQrcode_test.png');
let wxQrcode = require('@/assets/images/portal/wxQrcode_test.png');
let videoQrcode = require('@/assets/images/portal/videoQrcode_test.png');
if (env.isProd) {
  appQrcode = require('@/assets/images/portal/appQrcode.jpg');
  wxQrcode = require('@/assets/images/portal/wxQrcode.jpg');
  videoQrcode = require('@/assets/images/portal/videoQrcode.jpg');
}
const qrcodeList = Object.freeze([{
  type: 'wx',
  qrcode: wxQrcode,
  title: '华为JDC公众号',
  des: '获取智豆变更动态\n订阅最新有奖话题活动',
  logo: require('@/assets/images/portal/wxIcon.png')
}, {
  type: 'video',
  qrcode: videoQrcode,
  title: '华为JDC视频号',
  des: '分享数字化行业应用\n关注华为JDC官方视频号',
  logo: require('@/assets/images/portal/videoIcon.png')
}, {
  type: 'app',
  qrcode: appQrcode,
  title: '华为JDC移动端',
  des: '做行业数字化的人都在用的技术交流社区',
  logo: require('@/assets/images/portal/appIcon.png'),
  smLogo: require('@/assets/images/header/mobile_normal.png'),
  customerSMLogoStyles: {
    'background-size': 'auto 18px'
  }
}]);
export default {
  name: 'FooterJDC',
  data() {
    return {
      showCookie: false,
      webLink: webLink,
      qrcodeActive: qrcodeList[0],
      links: [],
      qrcodeList
    };
  },
  created() {
    this.checkCookie();
    this.getFriendLinks();
  },
  methods: {
    closeCookie() {
      cookie.setCookie('footer_cookie', 'footer', 30);
      this.showCookie = false;
    },
    checkCookie() {
      if (cookie.getCookie('footer_cookie') !== 'footer') {
        this.showCookie = true;
      }
    },
    handleQrcodeEnter(item) {
      this.qrcodeActive = item;
    },
    getFriendLinks() {
      this.links = [{
        link: webLink('huaweiCom'),
        name: '华为官网'
      }, {
        link: webLink('eHuawei'),
        name: '华为企业业务官网'
      }, {
        link: webLink('enterprise'),
        name: '企业互动社区'
      }, {
        link: webLink('supportEnterprise'),
        name: '企业技术支持'
      }, {
        link: webLink('bbs'),
        name: '华为云社区'
      }, {
        link: webLink('o3'),
        name: 'O3社区'
      }, {
        link: webLink('support'),
        name: '运营商技术支持'
      }];
    },
    // 跳转备案系统网址
    jumpRecordal() {
      windowOpen(this.webLink('recordal'));
    }
  }
};