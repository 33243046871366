// 区分国内版板块与国内版板块
import env from '@/config/env.js';
import { getFlagId } from '@/utils/constant/envVariable';

/**
 * 获取导航栏path字符串
 * @param {string} flag 导航菜单的标识
 * @returns {string}
 */
const getFlagRoute = flag => {
  return `viewForum?fid=${getFlagId(flag)}&flag=${flag}`;
};

/**
 * 海外版社区获取导航栏path字符串
 * @param {string|number} fid 圈子的id
 * @returns {string}
 */
const getRoutePath = fid => {
  return `viewForum?fid=${fid}`;
};

/**
 * 导航栏高亮检测
 * @param {*} flag 导航菜单的标识
 * @returns {function}
 */
const checkCircleFid = flag => {
  return () => {
    const fid = getFlagId(flag);
    const url = new URL(location.href);
    const queryFid = url.searchParams.get('fid');
    return queryFid === fid;
  };
};

/**
 * 海外版导航栏高亮检测
 * @returns {boolean}
 */
const checkCirclePath = () => {
  const reg = /viewForum\?fid=\d+/;
  const fid = getFlagId('suggest');
  const url = new URL(location.href);
  const queryFid = url.searchParams.get('fid');
  return reg.test(location.href) && queryFid !== fid;
};

const jdcMenus = [
  {
    name: '首页',
    path: 'portal',
  },
  {
    name: '活动',
    path: 'activity',
    isHot: true,
  },
  {
    name: '提产品建议',
    path: getFlagRoute('suggest'),
    activeSign: checkCircleFid('suggest'),
  },
  {
    name: '原创报告',
    path: getFlagRoute('originalReport'),
    activeSign: checkCircleFid('originalReport'),
    isHot: true,
  },
  {
    name: '问问同行',
    path: getFlagRoute('askpeers'),
    activeSign: checkCircleFid('askpeers'),
  },
  {
    name: '公开课',
    path: getFlagRoute('openClass'),
    activeSign: checkCircleFid('openClass'),
  },
  {
    name: '讨论',
    children: [
      {
        name: '互动讨论',
        path: getFlagRoute('interactionsuggest'),
        activeSign: checkCircleFid('interactionsuggest'),
      },
      {
        name: '精华讨论',
        path: getFlagRoute('discuss'),
        activeSign: checkCircleFid('discuss'),
      },
    ],
  },
  {
    name: '圈子',
    path: 'group',
  },
  {
    name: '智豆兑换',
    path: 'mall',
  },
];

const enMenus = [
  {
    name: 'Home',
    path: 'portal',
  },
  {
    name: 'Groups',
    path: getRoutePath(env.defaultCircleId),
    activeSign: checkCirclePath,
  },
  {
    name: 'Events',
    path: 'activity',
  },
  {
    name: 'Suggestions',
    path: getFlagRoute('suggest'),
    activeSign: checkCircleFid('suggest'),
  },
];

const menus = {
  jdc: jdcMenus,
  enJdc: enMenus,
};

export default menus[env.curVersionEnv];
