import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["href", "target", "rel", "title"];
const _hoisted_2 = {
  key: 1
};
import { computed, useAttrs } from 'vue';
import { navigatorLinkTarget } from './constant';
export default {
  __name: 'NavigatorLink',
  props: {
    src: String,
    // 是否新建浏览器标签页
    openOtherTab: {
      type: Boolean,
      default: true
    },
    // 是否允许此次跳转
    shouldNavigate: Function,
    title: String,
    target: String
  },
  setup(__props) {
    const props = __props;
    const attrs = useAttrs();
    const finalTarget = computed(() => {
      if (props.openOtherTab) {
        return navigatorLinkTarget.blank;
      }
      if (props.openOtherTab === false) {
        return navigatorLinkTarget.self;
      }
      return props.target;
    });
    const rel = computed(() => {
      if (finalTarget.value === navigatorLinkTarget.blank) {
        return 'noopener noreferrer';
      }
      return '';
    });
    const finalShouldNavigate = event => {
      const {
        shouldNavigate
      } = props;
      if (shouldNavigate && !shouldNavigate()) {
        event.preventDefault();
      }
    };
    return (_ctx, _cache) => {
      return Boolean(__props.src) ? (_openBlock(), _createElementBlock("a", _mergeProps({
        key: 0,
        href: __props.src,
        target: _unref(finalTarget),
        rel: _unref(rel),
        title: __props.title
      }, _unref(attrs), {
        onClick: finalShouldNavigate
      }), [_renderSlot(_ctx.$slots, "default")], 16, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]));
    };
  }
};