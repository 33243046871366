import axios from '@/axios/h5';
import refactorApi from '@/axios/refactorApi';
import { isMobile } from '@/utils/common/CommonUtils';

const addSearchLogUrl = refactorApi.post.addSearchLog;
const ANDROID = 'Android';
const IOS = 'iOS';
const mobile = isMobile();
/**
 * @param {String} val 查询的字符
 * @description deviceType:传1到4,1=web，2=安卓app，3=ios app，4=h5
 */
function addSearchLog(val) {
  let deviceType = 1;
  if (!mobile) {
    deviceType = 1;
  } else if (window.currentClient === ANDROID) {
    deviceType = 2;
  } else if (window.currentClient === IOS) {
    deviceType = 3;
  } else {
    deviceType = 4;
  }
  const params = {
    searchContent: val,
    deviceType,
  };
  return new Promise(resolve => {
    axios
      .post(addSearchLogUrl, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err);
      });
  });
}
export default addSearchLog;
