/**
 * 同 a 标签的 target 属性，
 */
export class NavigatorLinkTarget {
  self = '_self';
  blank = '_blank';
}

/**
 * 类似 ts 的枚举对象
 */
export const navigatorLinkTarget = new NavigatorLinkTarget();
