import 'core-js/stable';
import emotions from '@/utils/tinymce/emotions.js';
import { createHead } from 'unhead';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { filterXSS } from 'xss';

import i18n from '../../i18n/i18n';
import router from '../../router/refactorJdc.js';
import store from '../../store';

import index from './refactorJdc.vue';

import '../../../static_vue/icons/iconfont.js'; // iconfont字体
import '../../../static_vue/icons/iconfont.css';

import '@/../static_vue/js/code-prod.js';
import baseApi from '@/axios/baseApi.js';
import svgIcon from '@/components/common/SvgIcon/index';
import env from '@/config/env.js';
import pageCode from '@/utils/constant/pageCode';
import utils from '@/utils/main';
import elementIcon from '@/utils/web/elementIcon';
import elementPlus from '@/utils/web/elementPlus';
const head = createHead();
const codeImgMap = emotions;
const CUSTOM_IMG_WIDTH = 24;
const CUSTOM_IMG_HEIGHT = 24;

const app = createApp(index);

app.use(svgIcon);
app.use(head);
app.use(elementPlus);
app.use(elementIcon);
app.use(utils);
app.use(VueDOMPurifyHTML, {
  // 防止过滤链接中的target标签
  default: {
    ADD_ATTR: ['target', 'emojiname'], // 增加非过滤属性
    ADD_TAGS: ['emoji'], // 增加非过滤标签
  },
  hooks: {
    afterSanitizeAttributes: currentNode => {
      // 是emoji标签的话，替换为对应的图标表情
      if (currentNode.tagName === 'EMOJI') {
        const node = document.createElement('img');
        const emojiItem = codeImgMap.find(item => item.name === currentNode.attributes?.emojiname?.value);
        node.setAttribute('width', CUSTOM_IMG_WIDTH);
        node.setAttribute('height', CUSTOM_IMG_HEIGHT);
        node.setAttribute('alt', `custom-emoji-${emojiItem?.name}`);
        node.setAttribute('src', emojiItem?.url);
        currentNode.parentNode.replaceChild(node, currentNode);
      }
    },
  },
});

const item = Object.create(null);
Object.keys(baseApi).forEach(attr => {
  item[attr] = baseApi[attr];
});
app.config.globalProperties.$rm[baseApi.name] = item;
app.config.globalProperties.$rm.pageCode = pageCode;
app.config.globalProperties.$filterXSS = filterXSS;

app.config.globalProperties.isEnJdc = env.curVersionEnv === 'enJdc';
app.config.globalProperties.isOceanClub = env.curVersionEnv === 'oceanClubJdc';
app.config.globalProperties.isOverseas = ['enJdc', 'oceanClubJdc'].includes(env.curVersionEnv);
app.provide('isEnJdc', env.curVersionEnv === 'enJdc');

app.use(router);
app.use(store);
app.use(i18n);
app.mount('#jdc');
