import { cloneDeep } from '@plmcsdk/js-utility-library';
import emotions from '@/utils/tinymce/emotions';
import { ElMessage as Message } from 'element-plus';

const codeImgMap = emotions;
// 表情图片固定宽 px
const CUSTOM_IMG_WIDTH = 24;
// 表情图片固定高 px
const CUSTOM_IMG_HEIGHT = 24;

const broswer = function () {
  const sys = {};
  const ua = navigator.userAgent.toLowerCase();

  if (ua.match(/edge\/([\d.]+)/)) {
    sys.edge = ua.match(/edge\/([\d.]+)/)[1];
  } else if (ua.match(/rv:([\d.]+)\) like gecko/)) {
    sys.ie = ua.match(/rv:([\d.]+)\) like gecko/)[1];
  } else if (ua.match(/msie ([\d.]+)/)) {
    sys.ie = ua.match(/msie ([\d.]+)/)[1];
  } else if (ua.match(/firefox\/([\d.]+)/)) {
    sys.firefox = ua.match(/firefox\/([\d.]+)/)[1];
  } else if (ua.match(/chrome\/([\d.]+)/)) {
    sys.chrome = ua.match(/chrome\/([\d.]+)/)[1];
  } else if (ua.match(/opera.([\d.]+)/)) {
    sys.opera = ua.match(/opera.([\d.]+)/)[1];
  } else if (ua.match(/version\/([\d.]+).*safari/)) {
    sys.safari = ua.match(/version\/([\d.]+).*safari/)[1];
  }

  if (sys.edge) {
    return { broswer: 'Edge', version: sys.edge };
  }
  if (sys.ie) {
    return { broswer: 'IE', version: sys.ie };
  }
  if (sys.firefox) {
    return { broswer: 'Firefox', version: sys.firefox };
  }
  if (sys.chrome) {
    return { broswer: 'Chrome', version: sys.chrome };
  }
  if (sys.opera) {
    return { broswer: 'Opera', version: sys.opera };
  }
  if (sys.safari) {
    return { broswer: 'Safari', version: sys.safari };
  }

  return { broswer: '', version: '0' };
};
// 浏览器信息
const browserVersion = function (types) {
  let ver = 0;
  const BROWSER = {};
  const USERAGENT = navigator.userAgent.toLowerCase();
  Object.keys(types).forEach(i => {
    const v = types[i] ? types[i] : i;
    if (USERAGENT.indexOf(v) !== -1) {
      const re = new RegExp(`${v}(\\/|\\s|:)([\\d\\.]+)`, 'ig');
      const matches = re.exec(USERAGENT);
      ver = matches !== null ? matches[2] : 0;
    } else {
      ver = 0;
    }
    BROWSER[i] = ver;
  });
  return BROWSER;
};
const cookie = {
  getCookie(cname) {
    const name = `${cname}=`;
    const cookieArray = decodeURIComponent(document.cookie?.replace(/%/g, '%25')).split(';');
    for (let i = 0, len = cookieArray.length; i < len; i++) {
      let c = cookieArray[i];
      // 第一个键值对开头没有空格；后边的键值对开头都有空格，所以这里要判断一下
      if (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  setCookie(cname, cvalue = '', exdays = 30, domain = '.huawei.com') {
    const flag = typeof cname === 'string' && typeof cvalue === 'string' && cname.trim().length;
    if (flag) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=${cvalue};${expires};domain=${domain};path=/`;
    }
  },
  delCookie(cname) {
    document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.huawei.com;path=/`;
  },
};
const localStorageTime = {
  set(key, value, setDay) {
    const data = { value, expirse: new Date().getTime() + setDay * 24 * 60 * 60 * 1000 };
    window.localStorage.setItem(key, JSON.stringify(data));
  },
  get(key) {
    const data = JSON.parse(window.localStorage.getItem(key));
    if (data !== null) {
      if (data.expirse !== null && data.expirse < new Date().getTime()) {
        window.localStorage.removeItem(key);
        return null;
      } else {
        return data.value;
      }
    } else {
      return null;
    }
  },
};

/**
 * 文件下载
 * @param {String} fileName 文件名
 * @param {Blob} response 文件
 */
const downloadFile = (fileName, response) => {
  // 判断是否IE<11浏览器
  const isIE = window.ActiveXObject || 'ActiveXObject' in window;
  const blob = new Blob([response]);
  // 兼容IE浏览器blob下载处理
  if (isIE) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
};

/**
 * 处理blob返回数据
 * @param {blob} data 文件流数据
 * @param {function} callback 回调函数
 */
const handleBlobData = (data, callback) => {
  const reader = new FileReader();
  reader.readAsText(data, 'utf-8');
  reader.onload = () => {
    try {
      const { message } = JSON.parse(reader.result);
      Message.warning(message);
    } catch (error) {
      callback(data);
    }
  };
};

const isMobile = function () {
  return navigator.userAgent.match(
    /(iphone|android|phone|mobile|wap|netfront|java|opera mobi|opera mini|ucweb|windows ce|symbian|series|webos|sony|blackberry|dopod|nokia|samsung|palmsource|xda|pieplus|meizu|midp|cldc|motorola|foma|docomo|up.browser|up.link|blazer|helio|hosin|novarra|coolpad|webos|techfaith|palmsource|alcatel|amoi|ktouch|nexian|ericsson|philips|sagem|wellcom|bunjalloo|maui|smartphone|iemobile|spice|bird|zte-|longcos|pantech|gionee|portalmmm|jig browser|hiptop|benq|haier|^lct|320x320|240x320|176x220|windows phone|cect|compal|ctl|lg|nec|tcl|alcatel|ericsson|bird|daxian|dbtel|eastcom|pantech|dopod|philips|haier|konka|kejian|lenovo|benq|mot|soutec|nokia|sagem|sgh|sed|capitel|panasonic|sonyericsson|sharp|amoi|panda|zte|ipad)/i
  );
};

const isIOS = function () {
  const userAgent = navigator.userAgent;
  return /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
};

/**
 * @description 重写window.open，增加xss过滤、noopener noreferrer
 * @param url 跳转地址
 * @param target 打开方式 默认为 _blank
 */
const windowOpen = (url, target = '_blank') => {
  if (!url) {
    return;
  }
  window.open(window.filterXSS(url), target, 'noopener noreferrer');
};

/**
 *
 * @param {*} object 对象
 * @param {*} path 输入的路径
 * @param {*} defaultVal 默认值
 * @returns
 */
const get = function (object, path, defaultVal = undefined) {
  if (typeof object !== 'object' || typeof path !== 'string') {
    return defaultVal;
  }
  // 先将path处理成统一格式
  let newPath = [];
  if (Array.isArray(path)) {
    newPath = path;
  } else {
    // 先将字符串中的'['、']'去除替换为'.'，split分割成数组形式
    newPath = path.replace(/\[/g, '.').replace(/\]/g, '').split('.');
  }

  // 递归处理，返回最后结果
  return (
    newPath.reduce((o, k) => {
      return (o || {})[k];
    }, object) || defaultVal
  );
};

/**
 * 将富文本的gif表情图片替换成emoji标签 - 减少富文本长度
 */
const filterContentGif = editorEl => {
  editorEl?.dom?.select('img')?.forEach(item => {
    if (item?.alt?.startsWith('custom-emoji-')) {
      let node = document.createElement('emoji');
      node.setAttribute('emojiname', item?.alt.slice(13, item?.alt?.length));
      editorEl.dom.replace(node, item);
      node = null;
    }
  });
};

/**
 * 将富文本的emoji标签转换为gif表情图片 - tinymce编辑器回显使用
 */
const setContentGif = editorEl => {
  editorEl?.dom?.select('emoji')?.forEach(item => {
    let node = document.createElement('img');
    const emojiItem = codeImgMap.find(codeImg => codeImg.name === item.attributes?.emojiname?.value);
    node.setAttribute('width', CUSTOM_IMG_WIDTH);
    node.setAttribute('height', CUSTOM_IMG_HEIGHT);
    node.setAttribute('alt', `custom-emoji-${emojiItem.name}`);
    node.setAttribute('src', emojiItem.url);
    editorEl.dom.replace(node, item);
    node = null;
  });
};

// 自定义属性中，表情图片name属性开始的下标
const CUSTOM_NAME_START_INDEX = 13;
/**
 * 将html字符串中含有的gif表情图片替换成emoji标签
 */
const filterContentGifString = nodeStr => {
  if (!nodeStr) {
    return nodeStr;
  }
  let nodeTree = document.createRange().createContextualFragment(nodeStr);
  nodeTree.querySelectorAll('img')?.forEach(currentNode => {
    if (currentNode?.alt?.includes('custom-emoji-')) {
      let node = document.createElement('emoji');
      node.setAttribute('emojiname', currentNode?.alt.slice(CUSTOM_NAME_START_INDEX, currentNode?.alt?.length));
      currentNode.parentNode.replaceChild(node, currentNode);
      node = null;
    }
  });
  let outerNode = document.createElement('div');
  outerNode.appendChild(nodeTree);
  const innerHTML = cloneDeep(outerNode.innerHTML);
  outerNode.removeChild(outerNode.firstChild);
  outerNode = null;
  nodeTree = null;
  return innerHTML;
};

/**
 * 需要刷新tinymce的自动缓存：把tinymce记录的图片表情的src刷新成正确的src
 */
const updateImgSrcFromStorage = storageStr => {
  if (!storageStr) {
    return storageStr;
  }
  let nodeTree = document.createRange().createContextualFragment(storageStr);
  nodeTree.querySelectorAll('img')?.forEach(currentNode => {
    if (currentNode?.alt?.startsWith('custom-emoji-')) {
      currentNode.src = codeImgMap.find(codeImg => codeImg.name === currentNode?.alt.slice(CUSTOM_NAME_START_INDEX, currentNode?.alt?.length))?.url;
    }
  });
  let outerNode = document.createElement('div');
  outerNode.appendChild(nodeTree);
  const innerHTML = cloneDeep(outerNode.innerHTML);
  outerNode.removeChild(outerNode.firstChild);
  outerNode = null;
  nodeTree = null;
  return innerHTML;
};

/**
 * 获取当前富文本的自定义表情个数：计算回复占用字符
 */
const getCustomImgCount = editorEl => {
  let count = 0;
  editorEl?.dom?.select('img')?.forEach(item => {
    if (item?.alt?.startsWith('custom-emoji-')) {
      count++;
    }
  });
  return count;
};

/**
 * 处理web端at链接地址替换为h5地址
 */
const replaceAtUrl = (content, userUid) => {
  const reg = /<a\s+[^>]*href="([^"]*\/jdc\/refactor\/profile\?uid=[^"]*)"[^>]*>(.*?)<\/a>/g;
  content = content.replace(reg, (match, href, text) => {
    const uid = href.split('uid=')[1];
    if (Number(uid) === Number(userUid)) {
      return `<a href="/h5/personalData?uid=${uid}" rel="noopener noreferrer" style="color: rgb(0,182,182);">${text}</a>`;
    } else {
      return `<a href="/h5/hisHomePage?uid=${uid}" rel="noopener noreferrer" style="color: rgb(0,182,182);">${text}</a>`;
    }
  });
  return JSON.parse(JSON.stringify(content));
};

export {
  broswer,
  browserVersion,
  cookie,
  localStorageTime,
  downloadFile,
  handleBlobData,
  isMobile,
  isIOS,
  windowOpen,
  get,
  filterContentGif,
  setContentGif,
  filterContentGifString,
  updateImgSrcFromStorage,
  getCustomImgCount,
  replaceAtUrl,
};
