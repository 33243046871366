import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c4d8fee4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "breadcrumb"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "breadcrumb-nav"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavigatorLink = _resolveComponent("NavigatorLink");
  const _component_el_icon_arrow_right = _resolveComponent("el-icon-arrow-right");
  const _component_el_icon = _resolveComponent("el-icon");
  return $options.hasRouter ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.items, (item, index) => {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "breadcrumb-item"
    }, [_createVNode(_component_NavigatorLink, {
      "open-other-tab": false,
      src: item.href,
      title: $options.limitTitle(item.name, $props.limit),
      class: "nav-item"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($options.limitTextLength(item.name, $props.limit)), 1)]),
      _: 2
    }, 1032, ["src", "title"]), _createVNode(_component_el_icon, {
      class: "breadcrumb-separator"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon_arrow_right)]),
      _: 1
    })]);
  }), 128))])])])) : _createCommentVNode("", true);
}