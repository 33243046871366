import menus from '@/components/refactorJdc/header/constant';
export default {
  data() {
    return {
      menus,
      searchMenus: [...menus]
    };
  },
  props: {
    searchOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    searchOpen: {
      handler(val) {
        if (val) {
          // 搜索框展开时只展示7个tab页签
          this.searchMenus = this.menus.slice(0, 7);
        } else {
          this.searchMenus = this.menus;
        }
      },
      deep: true
    }
  },
  methods: {
    isCurrentMenuSelected(path, activeSign) {
      if (activeSign) {
        return this.isForumMenuActive(activeSign);
      }
      return this.getJumpRoute(path) === `/jdc${this.$route.path}`;
    },
    getJumpRoute(path) {
      if (!path) {
        return '#';
      }
      return `/jdc/refactor/${path}`;
    },
    subMenuClick(path) {
      window.location = this.getJumpRoute(path);
    },
    isForumActive(menuList) {
      if (!Array.isArray(menuList)) {
        return false;
      }
      return menuList.some(item => {
        return this.isForumMenuActive(item.activeSign);
      });
    },
    isForumMenuActive(activeSign) {
      if (!activeSign || typeof activeSign !== 'function') {
        return false;
      }
      return activeSign();
    }
  }
};