import env from '@/config/env';
import addSearchLog from '@/utils/common/addSearchLog.js';
import { isEmoji } from '@/utils/common/reg';
import { openLoginDialog } from '@/utils/web/commonUtils';
const typeObj = {
  1: '综合',
  2: '用户',
  3: '文档'
};
const COMMON_TYPE = '1'; // 综合类型
const USER_TYPE = '2'; // 用户类型
const PROTOCO = 'http://';
export default {
  data() {
    return {
      opened: false,
      // 打开完成
      dropdownVisible: false,
      // 下拉框切换状态
      type: COMMON_TYPE,
      srchtxt: '',
      typeObj
    };
  },
  emits: ['changeSearch'],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.user.baseInfo.uid;
    },
    userInfoGet() {
      return this.$store.state.user.userInfoGet;
    },
    typeName() {
      return this.typeObj[this.type];
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const {
          srchtxt,
          type
        } = to.query;
        if (!srchtxt || !type) {
          return;
        }
        this.srchtxt = srchtxt;
        this.type = type;
        this.$nextTick(() => {
          this.$emit('changeSearch', true);
        });
      }
    }
  },
  methods: {
    open() {
      // 判断是否登录，否则弹出登录窗
      if (!this.isLogin) {
        this.login();
        return;
      }
      this.$emit('changeSearch', true);
    },
    // 校验搜索是否更改-有输入值或者修改了下拉选项值
    checkIsChange() {
      return this.srchtxt.trim() || this.type !== COMMON_TYPE;
    },
    close() {
      // 如果下拉框是展开状态则不关闭
      if (this.dropdownVisible || this.checkIsChange()) {
        return;
      }
      this.$emit('changeSearch', true);
      this.opened = false;
      this.$refs.searchInput.blur();
    },
    setType(command) {
      this.type = command;
    },
    transitionEntered() {
      this.opened = true;
      this.$refs.searchInput.focus();
    },
    dropdownVisibleChange(visible) {
      this.dropdownVisible = visible;
    },
    searchTitle() {
      // 判断是否登录
      if (!this.isLogin) {
        this.login();
        return;
      }
      if (!this.isOpen) {
        this.$emit('changeSearch', true);
        return;
      }
      // 如果没有完全打开，则不搜索
      if (!this.opened) {
        return;
      }
      if (this.srchtxt.trim().length < 2) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.common.searchTips'),
          duration: 1500
        });
        return;
      }
      if (isEmoji(this.srchtxt)) {
        this.$message.warning('搜索内容中不能含有表情!');
        return;
      }
      if ([COMMON_TYPE, USER_TYPE].includes(this.type)) {
        const route = this.$router.resolve({
          path: '/refactor/findUser',
          query: {
            srchtxt: this.srchtxt,
            type: this.type
          }
        });
        window.open(route.href, '_blank', 'noopener');
        return;
      }

      // 文档跳转链接
      const BETA_LINK_URL = `${PROTOCO}rnd-devops.huawei.com/carriersearch/gsSearch#currentPage=1&groupSpaceId=ALL&size=10&topicordoc=doc&title=`;
      let href = env.currentEnv === 'prod' ? this.$t('lang.common.linkUrl') : BETA_LINK_URL;
      href = `${href}${encodeURIComponent(this.srchtxt)}&searchModelType=1`;
      // 添加搜索记录
      addSearchLog(this.srchtxt);
      window.open(href, '_blank', 'noopener');
    },
    login() {
      if (!this.isLogin && this.userInfoGet) {
        openLoginDialog();
      }
    }
  }
};